<template>
  <ReportForm :header="header" :allowToggle="false">
    <template #icons>
      <Checkbox v-model="enabled" :binary="true" :disabled="!formData.slack_channel || !allowDisable" />
    </template>

    <div v-if="formData.fields?.length">
      <div class="p-formgrid p-grid p-fluid">
        <FieldRenderList ref="fields" :fields="formData.fields" :disabled="!enabled" />
      </div>
    </div>
  </ReportForm>
</template>

<script setup>
import { computed, inject, ref } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import FieldRenderList from '../forms/FieldRenderList.vue'
import ReportForm from '../custom/ReportForm.vue'

const props = defineProps({
  formData: { type: Object, required: true },
  loading: { type: Boolean, default: true },
  allowDisable: { type: Boolean, default: false }
})

// data
const enabled = ref(true)
const fields = ref(null)

// computed
const header = computed(() => ({
  title: props.formData.eow_name,
  tag: {
    severity: props.formData?.slack_channel ? 'info' : 'danger',
    value: props.formData?.slack_channel || 'No slack channel'
  }
}))

// provide/inject
const _addEowData = inject('addEowData')

// methods
const addEowData = () => {
  const report = {
    id: props.formData?.id || uuidv4(),
    eow_id: props.formData.eow_id,
    eow_name: props.formData.eow_name,
    cost_center_id: props.formData.cost_center_id,
    cost_center_name: props.formData.cost_center_name,
    slack_channel: props.formData.slack_channel,
    fields: fields?.value?.fieldValues
  }
  _addEowData(report)
}

defineExpose({ addEowData, enabled })
</script>

<style>
</style>
