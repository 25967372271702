import { reactive } from 'vue'
import { parsePage } from '../utils/utilities'

/**
 * @typedef SlackChannel
 * @property {string|null} channel Slack channel name
 * @property {number|null} cost_center_id
 */

/**
 * @typedef EODFormField
 * @property {string} id
 * @property {string} type
 * @property {string} label
 * @property {?number} min
 * @property {?number} max
 * @property {number} priority
 * @property {?boolean} required
 * @property {?boolean} disable_paste
 * @property {Array<string>} select_options
 * @property {?"qualitative" | "quantitative"} question_type
 * @property {?boolean} is_there_a_parent
 * @property {?boolean} is_parent
 * @property {?Array<string>} tools
 */

/**
 * @typedef EODForm
 * @property {number} id EOD Form id
 * @property {string} name EOD Form name
 * @property {Array<EODFormField>} fields EOD Form fields
 */

/**
 * @typedef WorkLog
 * @property {number} duration worklog duration
 * @property {number} division_id Task cost center
 * @property {string} division_name Task cost center
 * @property {string} task_id Timedoctor task id
 * @property {string} task_name Timedoctor task name
 * @property {EODForm} eod_form EOD kpi form name
 */

/**
 * API response from /reporting/worklogs/
 * @typedef WorkLogs
 * @property {Array<WorkLog>} logs
 */

import useHttp from './useHttp'

export default () => {
  const http = useHttp()

  const fetchData = reactive({
    count: null,
    current: null,
    next: null,
    previous: null,
    results: null,
    params: {}
  })

  /**
   * @returns {Promise<import('axios').AxiosResponse<Array<SlackChannel>>>}
   */
  const fetchSlackChannels = async () => await http.exec({ url: '/reporting/slack-channels/' })

  const createReport = async (data) => await http.exec({ url: '/reporting/v2/reports/', method: 'POST', data })

  const deleteReport = async (id) => await http.exec({ method: 'DELETE', url: `/reporting/v2/reports/${id}/` })

  const fetchReports = async (params) => {
    const resp = await http.exec({ method: 'GET', url: '/reporting/reports/', params })
    if (!http.error.value) {
      const data = parsePage(resp.data)
      Object.assign(fetchData, { ...data, params })
    }
  }

  /**
   *
   * @param {number|null} duration
   * @returns {Promise<import('axios').AxiosResponse<WorkLogs>}
   */
  const fetchWorklogs = async (duration) =>
    await http.exec({ method: 'GET', url: '/users/recent-worklogs/', params: { period: duration } })

  // Retrieve eows to be rendered as forms
  const fetchEowObjects = async () => await http.exec({ method: 'GET', url: '/reporting/eows/forms/' })

  const getReport = async (id) => await http.exec({ method: 'GET', url: `/reporting/v2/reports/${id}/` })

  return {
    ...http,
    createReport,
    deleteReport,
    getReport,
    reportData: http.data,
    fetchData,
    fetchEowObjects,
    fetchReports,
    fetchSlackChannels,
    fetchWorklogs
  }
}
