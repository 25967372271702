<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-p-3 p-d-flex p-flex-column" style="gap: 2rem">
          <div>
            <Skeleton height="2rem" width="9rem" />
          </div>
          <div class="p-mt-3">
            <Skeleton height="2.5rem" width="50%" />
            <Skeleton class="p-mt-4" height="2.5rem" />
          </div>
          <div>
            <Skeleton height="10rem" />
            <Skeleton class="p-mt-4" height="10em" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
