<template>
  <Dialog
    contentClass="c-dialog"
    :visible="show"
    :closeOnEscape="false"
    :closable="false"
    :modal="true"
    :showHeader="false"
    :breakpoints="{ '992px': '75vw', '768px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="p-p-3">
      <Card class="flat">
        <template #title>
          <Message severity="warn" :closable="false"
            >This feature requires slack account. Click the button to continue.</Message
          >
        </template>
        <template #content>
          <div class="p-d-flex p-ai-center p-jc-center">
            <SlackButton />
          </div>
        </template>
      </Card>
    </div>
  </Dialog>
</template>

<script>
import SlackButton from './ConnectSlackButton.vue'
export default {
  name: 'ConfigureSlackModal',
  components: { SlackButton },
  props: { show: { required: true, default: false } }
}
</script>
