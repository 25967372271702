<template>
  <ConfigureSlackModal :show="!slackConfigured" />
  <div v-if="slackConfigured && slackConfigured !== 'loading'" class="p-grid">
    <div class="p-col-12">
      <h4>End of Week Report</h4>
      <ReportFormSkeleton class="p-mb-5" v-if="!doneLoading" />
      <div v-else>
        <div class="p-mb-5" v-for="(obj, i) in eowObjects" :key="obj.eow_id">
          <EOWForm
            :allowDisable="eowObjects.length > 1"
            :formData="obj"
            :ref="(el) => (el ? (formRefs[i] = el) : null)"
          />
        </div>
        <div v-if="!eowObjects.length" class="card">
          <h5 class="p-text-center">End of Week reporting is not available for your OS</h5>
        </div>
      </div>
      <div v-if="eowObjects.length || !doneLoading" class="p-d-flex p-jc-end">
        <Button label="Submit" :disabled="!hasEnabled || v.$invalid" :loading="loading" @click="handleSubmit($event)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useVuelidate } from '@vuelidate/core'

import EOWForm from '../../components/reporting/EOWForm.vue'
import ConfigureSlackModal from '@/components/custom/ConfigureSlackModal.vue'
import ReportFormSkeleton from '../../components/skeletons/ReportFormSkeleton.vue'

import useReport from '../../hooks/useReport'
import { handleHttpError } from '../../utils/utilities'
import { REPORT_TYPE } from '../../definitions'

defineEmits(['prev-page', 'next-page', 'submit-eod'])

const confirm = useConfirm()
const route = useRoute()
const router = useRouter()
const toast = useToast()
const { createReport, error, fetchEowObjects, loading } = useReport()

const eowObjects = ref([])
const eowData = ref([])
const formRefs = ref([])
const doneLoading = ref(false)

// computed
const hasEnabled = computed(() => formRefs.value.some((el) => el.enabled))

// provide/inject
const slackConfigured = inject('slackConfigured')
provide('eowObjects', eowObjects)
provide('eowData', eowData)
provide('addEowData', (data) => eowData.value.push(data))
provide('clearEowData', () => (eowData.value = []))

// watchers
watch(error, (value) => {
  handleHttpError(value, toast, 'Unable to process your request.')
})

const handleSubmit = (event) => {
  const submit = async () => {
    eowData.value = []
    formRefs.value.filter((form) => form.enabled).forEach((form) => form.addEowData())
    const report = {
      type: REPORT_TYPE.EOW,
      forms: eowData.value
    }
    return await createReport(report)
  }

  confirm.require({
    target: event.currentTarget,
    message: 'Are you sure you want to proceed?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      const resp = await submit()
      if (!error.value && resp.data) {
        console.log(error.value)
        router.replace({ name: 'eow-detail', params: { reportId: resp.data.id } })
      }
    }
  })
}

// validation
const v = useVuelidate()

// created
;(async () => {
  if (route.name !== 'create-eow') router.push({ name: 'create-eow' })
  const resp = await fetchEowObjects()
  if (!error.value && resp?.data) {
    eowObjects.value = resp.data
    doneLoading.value = true
  }
})()
</script>
