<template>
  <Panel :toggleable="allowToggle">
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center p-p-3">
        <h4 data-test="title" class="p-m-0">
          <a v-if="header.slackUrl" :href="header.slackUrl" target="_blank"
            >{{ header.title }}<i class="pi pi-external-link p-ml-2"></i
          ></a>
          <span v-else>{{ header.title }}</span>
        </h4>
        <Tag
          v-if="header.tag"
          data-test="tag"
          class="p-ml-1"
          :severity="header.tag.severity"
          :value="header.tag.value"
        ></Tag>
      </div>
    </template>
    <template #icons>
      <div class="p-p-3">
        <slot name="icons"></slot>
      </div>
    </template>
    <div class="p-px-3 p-pb-3">
      <slot></slot>
    </div>
  </Panel>
</template>

<script>
export default {
  name: 'ReportForm',
  emits: ['delete'],
  props: {
    id: { type: [String, Number], required: false },
    header: { type: [String, Object], required: true },
    allowToggle: { default: true }
  }
}
</script>

<style scoped>
</style>
